import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { ListGeographies } from 'pages/geographies/list/ListGeographies'
import { UpsertGeography } from 'pages/geographies/upsert/UpsertGeography'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertGeography = (
  <UpsertEntityProvider>
    <UpsertGeography />
  </UpsertEntityProvider>
)

export const Geographies = () => (
  <Routes>
    <Route path={routesManager.masterData.geographies.root.relativePattern} index element={<ListGeographies />} />
    <Route path={routesManager.masterData.geographies.create.relativePattern} element={WrappedUpsertGeography} />
    <Route path={routesManager.masterData.geographies.update.relativePattern} element={WrappedUpsertGeography} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
