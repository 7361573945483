import { WppActionButton, WppButton, WppIconTrash } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateGeographyApi } from 'api/masterData/mutations/useCreateGeographyApi'
import { useEditGeographyApi } from 'api/masterData/mutations/useEditGeographyApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { showDeleteGeographyModal } from 'pages/geographies/deleteGeographyModal/DeleteGeographyModal'
import { convertFormToFormDto } from 'pages/geographies/upsert/utils'
import { handleReloadGeographiesListTable } from 'pages/geographies/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Geography, GeographyForm } from 'types/masterData/geographies'
import { routesManager } from 'utils/routesManager'

interface Props {
  geography: Geography
  form: UseFormReturn<GeographyForm>
}

export const ActionButtons = ({ geography, form }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const {
    contextState: { nonCurrentVersionSelected },
    handleSelectVersion,
  } = useUpsertEntityContext()
  const { mutateAsync: handleCreate } = useCreateGeographyApi()
  const { mutateAsync: handleEdit } = useEditGeographyApi()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: GeographyForm) => {
    try {
      const { data: createdGeography } = await handleCreate({
        geography: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.geographies.update.getURL({ entryId: createdGeography?.id }))

      await handleReloadGeographiesListTable()
      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.geography') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: GeographyForm) => {
    try {
      await handleEdit({
        id: geography!.id,
        geography: convertFormToFormDto(values),
      })

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadGeographiesListTable(geography!.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.geography') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () =>
    showDeleteGeographyModal({
      geography,
      onAfterDelete: () => {
        navigate(routesManager.masterData.geographies.root.getURL())
      },
    })

  return (
    <>
      {geography && (
        <WppActionButton variant="destructive" disabled={nonCurrentVersionSelected} onClick={handleDelete}>
          <WppIconTrash slot="icon-start" />
          {t('common.delete')}
        </WppActionButton>
      )}
      <WppButton variant="secondary" disabled={!isDirty || nonCurrentVersionSelected} onClick={() => reset()}>
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        disabled={nonCurrentVersionSelected}
        onClick={() => handleSubmit(geography ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )
}
