import { Route, Routes } from 'react-router-dom'

import { NotFound } from 'pages/404/NotFound'
import { ListBrands } from 'pages/brands/list/ListBrands'
import { UpsertBrand } from 'pages/brands/upsert/UpsertBrand'
import { UpsertEntityProvider } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { routesManager } from 'utils/routesManager'

const WrappedUpsertBrand = (
  <UpsertEntityProvider>
    <UpsertBrand />
  </UpsertEntityProvider>
)

export const Brands = () => (
  <Routes>
    <Route path={routesManager.masterData.brands.root.relativePattern} index element={<ListBrands />} />
    <Route path={routesManager.masterData.brands.create.relativePattern} element={WrappedUpsertBrand} />
    <Route path={routesManager.masterData.brands.update.relativePattern} element={WrappedUpsertBrand} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
